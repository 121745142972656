<template>
	<div class="">
		<router-view v-if="isLogin" />
		<layout-horizontal v-else>
			<router-view />

			<template #navbar="{ toggleVerticalMenuActive }">
				<app-navbar-horizontal-layout-brand />
				<navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" :user-data="user" @logout="logout" />
			</template>
			<div slot="breadcrumb">
				<PWPBreadCrumb />
			</div>
		</layout-horizontal>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue';
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue';
import Navbar from '../../components/Navbar.vue';
import PWPBreadCrumb from '../../components/BreadCrumb.vue';

export default {
	components: {
		LayoutHorizontal,
		Navbar,
		AppNavbarHorizontalLayoutBrand,
		PWPBreadCrumb
	},

	data: () => ({}),

	computed: {
		isLogin() {
			return this.$route.meta?.layout === 'full';
		},
		...mapState('auth', ['user'])
	},

	methods: {
		async logout() {
			await store.dispatch('auth/logout');
			this.$router.push({ name: 'login' });
		}
	}
};
</script>
