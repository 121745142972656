export default [
	{
		title: 'Home',
		route: 'home',
		icon: 'HomeIcon',
	},
	{
		title: 'Projects',
		route: 'projects',
		icon: 'HomeIcon',
	},
	{
		title: 'Form Builder',
		route: 'forms',
		icon: 'FileTextIcon',
	},
	{
		title: 'M&E',
		route: 'm&e',
		icon: 'PieChartIcon',
	},
	{
		title: 'GRM',
		route: 'grm',
		icon: 'BarChart2Icon',
	},
	{
		title: 'Migration',
		route: 'migration',
		icon: 'RepeatIcon',
	},
	{
		title: 'Reports',
		icon: 'PieChartIcon',
		children: [
			{
				title: 'Submissions',
				route: 'submissions',
				icon: 'CircleIcon',
			},
			{
				title: 'Progress',
				route: 'progress',
				icon: 'CircleIcon',
			},
			{
				title: 'Project List',
				route: 'project-list',
				icon: 'CircleIcon',
			},
			{
				title: 'Project Forms',
				route: 'project-forms',
				icon: 'CircleIcon',
			},
			{
				title: 'Projects insight',
				route: 'projects-insight',
				icon: 'CircleIcon',
			},
			{
				title: 'Baseline reports',
				route: 'baseline',
				icon: 'CircleIcon',
			},
			{
				title: 'Beneficiary insight',
				route: 'beneficiary-insight',
				icon: 'CircleIcon',
			},
			{
				title: 'Beneficiary Paying insight',
				route: 'beneficiary-paying-insight',
				icon: 'CircleIcon',
			},

			/* Safeguard reports */
			{
				title: 'Safeguard reports',
				icon: 'CircleIcon',
				children: [
					{
						title: 'ESMPs indicators',
						route: 'reports.esmps-indicators',
						icon: 'CircleIcon',
					},

					{
						title: 'Number of accidents and man work Hours.',
						route: 'reports.accidents-and-work-hours',
						icon: 'CircleIcon',
					},
					{
						title: 'Location visits',
						route: 'reports.location-visits',
						icon: 'CircleIcon',
					},

					{
						title: 'GRM',
						route: 'reports.grm',
						icon: 'CircleIcon',
					},

					{
						title: 'Main weakness corrective actions details',
						route: 'reports.main-weakness-corrective-actions-details',
						icon: 'CircleIcon',
					},

					{
						title: 'training raise awareness details',
						route: 'reports.training-raise-awareness-details',
						icon: 'CircleIcon',
					},
				]
			},
		],
	},
	{
		title: 'Settings',
		icon: 'SettingsIcon',
		children: [
			{
				title: 'Users',
				route: 'users',
				icon: 'CircleIcon',
			},
		],
	},
];
