<template>
	<b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
		<template #button-content>
			<feather-icon :badge="count" badge-classes="bg-success p-0" class="text-body" icon="BellIcon" size="24" />
		</template>

		<!-- Header -->
		<li class="dropdown-menu-header">
			<div class="dropdown-header d-flex">
				<h4 class="notification-title mb-0 mr-auto">
					<span v-if="notifications.length">{{ count }}</span> Notifications
				</h4>
				<b-badge pill variant="light-primary"> New </b-badge>
			</div>
		</li>

		<!-- Notifications -->
		<vue-perfect-scrollbar v-once :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area" tagname="li">
			<!-- Account Notification -->
			<template v-if="notificationsToShow.length > 0">
				<div v-for="notification in notificationsToShow" :key="notification.subtitle">
					<b-media>
						<template #aside>
							<b-avatar size="38" :variant="`light-${notification.color}`">
								<feather-icon size="20" :icon="notification.icon"></feather-icon>
							</b-avatar>
						</template>
						<div class="">
							<div class="d-flex justify-content-between">
								<p class="media-heading mb-50">
									<span class="font-weight-bolder">{{ notification.title }} </span>
								</p>
								<div class="d-flex ">
									<span class="text-primary" style="font-size: 12px; font-weight: bold"
										>{{ notification.project_id }}
									</span>
								</div>
							</div>
							<small class="d-inline-block  notification-text">{{ notification.message }}</small>
						</div>
					</b-media>
				</div>
			</template>
			<div v-else class="py-1">
				<p class="mb-0 text-center text-muted">There's no new notifications !</p>
			</div>
		</vue-perfect-scrollbar>

		<!-- Cart Footer -->
		<li class="dropdown-menu-footer">
			<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block :to="{ name: 'notifications' }">
				All notifications
			</b-button>
		</li>
	</b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BBadge, BAvatar, BMedia, BButton } from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Ripple from 'vue-ripple-directive';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
	components: {
		BNavItemDropdown,
		BBadge,
		BAvatar,
		BMedia,
		VuePerfectScrollbar,
		BButton,
		FeatherIcon,
	},

	directives: {
		Ripple,
	},

	props: {
		notifications: {
			type: Array,
			default: () => [],
		},
		count: {
			type: Number,
			default: 0,
		},
	},

	data: () => ({
		perfectScrollbarSettings: {
			maxScrollbarLength: 60,
			wheelPropagation: false,
		},
	}),

	computed: {
		notificationsToShow() {
			return this.notifications.slice(0, 10);
		},
	},
};
</script>

<style></style>
