<template>
	<div class="navbar-container d-flex content align-items-center">
		<!-- Nav Menu Toggler -->
		<ul class="nav navbar-nav d-xl-none">
			<li class="nav-item">
				<b-link class="nav-link" @click="toggleVerticalMenuActive">
					<feather-icon icon="MenuIcon" size="21" />
				</b-link>
			</li>
		</ul>

		<!-- Left Col -->
		<div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
			<!-- <dark-Toggler class="d-none d-lg-block" /> -->
		</div>

		<b-navbar-nav class="nav align-items-center ml-auto" style="gap: 1rem">
			<notifications-menu :notifications="notifications" :count="count" />

			<b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link">
				<template #button-content>
					<b-avatar
						size="40"
						variant="light-primary"
						badge
						:src="userData.avatar"
						class="badge-minimal"
						badge-variant="success"
					/>
				</template>

				<b-dropdown-text>
					<div class="d-sm-block d-none user-nav">
						<span class="d-block font-weight-bold mb-0 text-truncate">
							{{ userData.name }}
						</span>
					</div>
				</b-dropdown-text>

				<b-dropdown-divider />

				<b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'user-profile' }">
					<feather-icon size="16" icon="UserIcon" class="mr-50" />
					<span>Profile</span>
				</b-dropdown-item>
				<!-- <b-dropdown-divider /> -->

				<b-dropdown-item link-class="d-flex align-items-center" @click="$emit('logout')">
					<feather-icon size="16" icon="LogOutIcon" class="mr-50" />
					<span>Logout</span>
				</b-dropdown-item>
			</b-nav-item-dropdown>
		</b-navbar-nav>
	</div>
</template>

<script>
import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownText, BDropdownDivider, BAvatar } from 'bootstrap-vue';
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import NotificationsMenu from '@/components/NotificationsMenu.vue';
import { mapState } from 'vuex';

export default {
	components: {
		BLink,
		BNavbarNav,
		BNavItemDropdown,
		BDropdownItem,
		BDropdownText,
		BDropdownDivider,
		BAvatar,

		// Navbar Components
		// DarkToggler,
		NotificationsMenu,
	},

	props: {
		toggleVerticalMenuActive: {
			type: Function,
			default: () => {},
		},

		userData: {
			type: Object,
			default: () => ({
				id: 1,
				name: 'Mohamed Alghobary',
			}),
		},
	},

	computed: {
		...mapState({
			notifications: (state) => state.notifications.newNotifications,
			count: (state) => state.notifications.count,
		}),
	},
};
</script>
